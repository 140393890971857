import { React, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { TextField, useRecordContext } from 'react-admin';

const MouseHoverPopoverField = ({ source, label, maxWidth = '300px', className = '' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const record = useRecordContext();
  if (!record) return null;

  const text = record[source];

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        className={`mouse-hover-popover-field ${className}`}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        maxWidth={maxWidth}
      >
        <TextField
          source={source}
          label={label}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus
      >
        <Typography sx={{ p: 1 }}>{text}</Typography>
      </Popover>
    </>
  );
};

export default MouseHoverPopoverField;
